// eslint-disable-next-line @nx/enforce-module-boundaries
import packageJSON from "../../../../../../package.json";

const version = packageJSON?.version?.split(".");

export function getAppVersion(): { major: string; minor: string; patch: string } {
  return {
    major: version?.at(0),
    minor: version?.at(1),
    patch: version?.at(2),
  };
}
